import React, {useEffect, useRef, useState} from 'react';
import ebookHeadTablet from '../../assets/tab_phone.png'
import realBook from '../../assets/realBookResized.png'
import "./landing_page.css"
import {Feature} from "../../components";
import emailService from "../../services/mail.service";
import {useLocation} from "react-router-dom";

const LandingPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [activeButton, setActiveButton] = useState(".EPUB");
    const [fileExtension, setFileExtension] = useState(".EPUB");
    const inputRef = useRef(null);
    const location = useLocation();
    useEffect(() => {
        if(loading) {
            sendEmail(emailAddress);
            setLoading(false)
        }
    }, [loading, emailAddress]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]); // Scroll to top on route change

    const setDestination = (email) => {
        email.preventDefault();
        setEmailAddress(email.target.value);

    };

    const handleScrollClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

    };

    const fileSelection = (selection) => {
        if (selection.target.textContent === ".EPUB"){
            setActiveButton(selection.target.textContent);
            setFileExtension(selection.target.textContent);
        } else if (selection.target.textContent === ".PDF"){
            setActiveButton(selection.target.textContent);
            setFileExtension(selection.target.textContent);
        } else if (selection.target.textContent === ".Docx"){
            setActiveButton(selection.target.textContent);
            setFileExtension(selection.target.textContent);

        } else if (selection.target.textContent === ".AZW"){
            setActiveButton(selection.target.textContent);
            setFileExtension(selection.target.textContent);

        }
        else {
            setActiveButton(".EPUB");
            setFileExtension(".EPUB");

        }
    };

    const sendEmail = (email) => {
        emailService.sendEmail(
            email,
            "FightxIQ: Better Bets and Winning UFC Strategies FREE download.",
            "ebookHtml",
            fileExtension.toLowerCase())
            .then(response => {
                props.showToastMessage(response.data.message);
                setLoading(false);
        }).catch(error => {
            console.log("CATCH ", error);
            props.showToastMessage(error.response.data.message);
            setLoading(false);
        })
        setLoading(false);

    };

    return (
        <div className="landing-page ">
            <div className="landing-page-header gradient__bg">
                <div className="landing-page-header-row">

                    <div className="landing-page-header-column grid-a">
                        <h1><span className="gradient__text-ebook">Get your</span> <u>FREE</u> <span
                            className="gradient__text-ebook">copy of </span><span
                            className="landing-page-header-fightxiq">Fight<span
                            className="landing-page-header-x">x</span>IQ</span> <span className="gradient__text-ebook">Better Bets and
                            Winning UFC</span> <span className="gradient__text-ebook">Strategies</span> <u>E-book
                            Today!</u>
                        </h1>
                        <p>
                            Enter your email to receive your FREE copy of our UFC betting strategy eBook.
                            Increase your return on investment (ROI) with or without the FightxIQ Predictions Dashboard
                            by considering our insight from this eBook.

                        </p>
                        <div className="landing-page-header-column-input">
                            <input ref={inputRef} onChange={(e) => setDestination(e)} type="email"
                                   placeholder="Email*"/>
                            <button
                                disabled={loading}
                                onClick={() =>setLoading(true)}

                                type='button'>Download Ebook</button>

                        </div>
                        <div className="landing-page-header-file-selection">
                            <a className="file-selection-button"
                               style={{opacity: activeButton === ".EPUB" ? 1 : 0.5}}
                               onClick={(e) => fileSelection(e)}>
                                <h2>.EPUB</h2>
                            </a>
                            <a className="file-selection-button"
                               style={{opacity: activeButton === ".PDF" ? 1 : 0.5}}
                               onClick={(e) => fileSelection(e)}>
                                <h2>.PDF</h2>
                            </a>
                            <a className="file-selection-button"
                               style={{opacity: activeButton === ".Docx" ? 1 : 0.5}}
                               onClick={(e) => fileSelection(e)}>
                                <h2>.Docx</h2>
                            </a>
                            <a className="file-selection-button"
                               style={{opacity: activeButton === ".AZW" ? 1 : 0.5}}
                               onClick={(e) => fileSelection(e)}>
                                <h2>.AZW</h2>
                            </a>


                        </div>
                        <label className="label_file_select">Select File Extension</label>

                    </div>
                    <div className="landing-page-header-column grid-b">
                        <img src={ebookHeadTablet} alt="ebook header" height={750}/>
                    </div>
                </div>

            </div>


            <div className='iq__whatiq section__margin section__padding' id="whatIq">
                <div className='iq__whatiq-feature'>
                    <Feature title="What's Inside?" text="FightxIQ: Better Bets and Winning UFC Strategies, is a
                        comprehensive guide to making data-driven bets on UFC fights. It emphasizes the importance of
                        analytics over instinct in sports gambling, showcasing how AI tools like FightxIQ can help gamblers
                        predict outcomes with accuracy. The content outlines betting basics, explains how to interpret
                        and capitalize on odds, and highlights the power of machine learning in analyzing extensive UFC
                        data to level the playing field against bookmakers. The guide also provides insights into
                        FightxIQ’s dashboard and predictive capabilities, encouraging responsible gambling and
                        strategic planning."
                    />
                </div>
                <div className='iq__whatiq-heading'>
                    <h1 className='gradient__text'> Beat The Bookies & Increase Your Return on Investment Today.</h1>

                </div>
                <div className='iq__whatiq-container'>
                    <Feature title="Beat the Bookmakers" text="Understand the intricacies of odds and how to spot opportunities where the house might falter;
                        Highlighting your opportunity to capitalize using your plethora of data and knowledge."/>
                    <Feature title="Data-Driven Betting Strategies" text="Discover how to analyze fighter stats, trends,
                         and historical data to make smarter, more informed UFC wagers."/>
                    <Feature title="Understanding AI & Betting" text=" Learn how to leverage over 20 years of
                        UFC data and FightxIQ AI Predictions to predict fight outcomes; Transforming vast amounts of
                        information into actionable insights for smarter betting."/>
                </div>
            </div>

            <div className="landing-page-content_chapters section__margin section__padding">


                <div className="landing-page-content-header ">
                    <h2 className="gradient__text">Book Chapters
                    </h2>
                </div>
                <h4 className="landing-page-content-action"
                    onClick={handleScrollClick}>
                    Grab your FREE copy now!

                </h4>
                <div className="landing-page-content-img">

                    <img src={realBook} height={700} alt=""/>

                </div>

                <ol className="alternating-colors" start="0">
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Introductions</strong>
                        <p>Hello.</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 1:
                        </strong>
                        <p> Watching the Odds</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 2:
                        </strong>
                        <p>How to Beat the Bookies</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 3:
                        </strong>
                        <p>Leveling the Field with FightxIQ</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 4:
                        </strong>
                        <p>The Nuts and Bolts of using AI to Bet on UFC</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 5:
                        </strong>
                        <p>The FightxIQ Dashboard</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 6: </strong>
                        <p>Final Thoughts</p>
                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Our Appreciation
                        </strong>
                        <p>Subscription Discount Coupon</p>

                    </li>
                </ol>

            </div>
        </div>
    );
};

export default LandingPage;