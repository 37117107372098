import axios from "axios";
import authHeader from "./auth-header";
const HOME_URL = process.env.REACT_APP_DASHBOARD_URL;
const axiosHome = axios.create({baseURL: HOME_URL});

class EmailService {
    async sendEmail(email, subject, message, fileExtension) {
        return await axiosHome.post("email/ebook",
            {
                email,
                subject,
                message,
                fileExtension
            }, { headers: authHeader() });

    };

}

const emailService = new EmailService();
export default emailService;