import React, {useEffect, useRef} from 'react';
import realBook from "../../assets/tab_front_resized.png";
import "./ebook_preview.css"
import logo from "../../assets/logo.png";
import {useLocation, useNavigate} from "react-router-dom";
const EbookPreview = () => {
    const navigation = useNavigate();
    const location = useLocation();



    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]); // Scroll to top on route change


    return (
        <div className="ebook_preview-container">
            <div className="ebook_preview-headline">
                <h1><span
                    className="landing-page-header-fightxiq">Fight<span
                    className="landing-page-header-x">x</span>IQ</span><br/> <span className="gradient__text">Better Bets and
                            Winning UFC</span> <span className="gradient__text">Strategies</span>
                </h1>
                <button className="ebook_preview-header-button"
                        onClick={() => navigation("/ebook")}>Download Free NOW
                </button>
            </div>
            <div className="ebook_preview-img">
                <img src={realBook} height={550} alt="ebookPreview"/>

            </div>
            <div className="ebook_preview-includes">
                <h1 className="ebook_preview-book-header">CHAPTERS</h1>

                <ol className="ebook_preview_alternating-colors" start="0">

                    <li>
                        <div className="gradient__bar"/>

                        <strong>Introductions</strong>
                        <p>Hello.</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 1:
                        </strong>
                        <p> Watching the Odds</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 2:
                        </strong>
                        <p>How to Beat the Bookies</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 3:
                        </strong>
                        <p>Leveling the Field with FightxIQ</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 4:
                        </strong>
                        <p>The Nuts and Bolts of using AI to Bet on UFC</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 5:
                        </strong>
                        <p>The FightxIQ Dashboard</p>

                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Chapter 6: </strong>
                        <p>Final Thoughts</p>
                    </li>
                    <li>
                        <div className="gradient__bar"/>

                        <strong>Our Appreciation
                        </strong>
                        <p>Subscription Discount Coupon</p>

                    </li>
                </ol>
            </div>



        </div>
    );
};

export default EbookPreview;