import React from 'react';
import "../../App.css";
import authService from '../../services/auth.service'
import { useState, useEffect } from 'react';
import "./account_dashboard.css";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import iqlogo from "../../assets/logo.png"
import LoadingScreen from '../../components/loading/LoadingScreen';
import authHeader from '../../services/auth-header';

const AccountDashboard = (props) => {


    const [currentUser, setCurrentUser] = useState({});
    const [userReady, setUserReady] = useState(false);
    const [redirect, setRedirect] = useState(null);
    const [errMessage, setErrMessage] = useState("");
    const [pressedLoading, setPressedLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const nowUser = authService.getCurrentUser();
        if (!nowUser) setRedirect("/");
        setCurrentUser(nowUser);
        setUserReady(true);

        setLoading(false);

    }, []);


    const cancelButton = (e) => {
        e.preventDefault();

        if (checked) {
            setPressedLoading(true);


            authService.cancelSubscription(currentUser, authHeader).then((sub) => {
                const cancelData = sub.data;
                props.setSubscriptionInfo(prevState => ({
                    ...prevState,
                    isPaused: false,
                    status: cancelData.status,
                    chargedThroughDate: cancelData.charged_through_date,




                }));

                props.showToastMessage(sub.data.message, "success");
                setChecked(false);
                setPressedLoading(false);

            }).then((e) => { navigate(0) }).catch(error => {
                setPressedLoading(false);
                setChecked(false);


                if (error.response.status === 400) {
                    props.showToastMessage(error.response.data.message);

                    setErrMessage(error.response.data.message);

                }

            });
        } else {
            props.showToastMessage("Please confirm your action.", "info");
            setErrMessage("Please confirm your action.");

        }
    };


    const resumeButton = (e) => {
        //setSuccessMessage(e.message)
        e.preventDefault();


        if (checked) {

            setPressedLoading(true);


            authService.resumeSubscription(currentUser, authHeader)
                .then((sub) => {
                    const cancelData = sub.data;


                    props.setSubscriptionInfo(prevState => ({
                        ...prevState,
                        isPaused: false,
                        status: cancelData.status,
                        chargedThroughDate: cancelData.charged_through_date,




                    }));

                    props.showToastMessage(sub.data.message, "success");
                    setChecked(false);
                    setPressedLoading(false);
                })
                .catch(error => {
                    setPressedLoading(false);
                    setChecked(false);
                    if (error.response.status === 400) {
                        props.showToastMessage(error.response.data.message, "error");

                        setErrMessage(error.response.data.message);

                    }
                    props.showToastMessage(error.response.data.message, "error");

                });
        } else {
            props.showToastMessage("Please confirm your action.", "info");
            setErrMessage("Please confirm your action.");

        }

    };

    const handleCheckbox = () => {
        setChecked(!checked)
    };

    if (redirect) {
        return <Navigate to={redirect} />
    }
    if (props.subscriptionInfo.isLoading || pressedLoading || loading) {
        return <LoadingScreen />
    }





    if (props.subscriptionInfo.status === "LIFETIME") {




        return (
            <div className='iq__account'>


                <div className='iq__account-header'>
                    <h1>Account Dashboard</h1>
                </div>
                <div className='iq__account-div'>
                    <img src={iqlogo} alt="logo"></img>

                    <div className='iq__account-details'>


                        <div className='iq__account-details-container'>

                            <h3>Account Details
                            </h3>

                            <h4>Email:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.email}</p>

                            <h4>Username:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.username}</p>

                        </div>

                        <div className='iq__account-details-subscription'>


                            <h3>Subscription Status

                            </h3>
                            <h4>Status:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.status}</p>
                            <h4>Current Plan:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.plan}</p>

                            <h4>Next Payment:
                                <div className='border_div' />
                            </h4>
                            <p>{props.subscriptionInfo.chargedThroughDate}</p>



                        </div>

                    </div>
                    <div className='iq__account-details-subscription_button'>
                        <button onClick={(e) => navigate("/predictions")}>Predictions Dashboard</button>

                    </div>

                </div>



            </div >
        );
    };
    // render account page of canceled subscription
    // with time left on billing cylce
    if (props.subscriptionInfo.isPaused) {

        return (
            <div className='iq__account'>


                <div className='iq__account-header'>
                    <h1>Account Dashboard</h1>
                </div>
                <div className='iq__account-div'>
                    <img src={iqlogo} alt="logo"></img>

                    <div className='iq__account-details'>

                        <div className='iq__account-details-container'>

                            <h3>Account Details
                            </h3>

                            <h4>Email:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.email}</p>

                            <h4>Username:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.username}</p>

                        </div>

                        <div className='iq__account-details-subscription'>


                            <h3>Subscription Status

                            </h3>
                            <h4>Status:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.status}</p>
                            <p className='iq__account-paused_label'>
                                Your payments are scheduled to stop on:
                                {props.subscriptionInfo.chargedThroughDate}
                            </p>


                            <h4>Current Plan:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.plan}</p>


                            <h4>Next Payment:
                                <div className='border_div' />
                            </h4>
                            <p>{props.subscriptionInfo.chargedThroughDate}</p>



                        </div>

                    </div>

                    <div className='iq__account-details-subscription_button'>
                        <button onClick={resumeButton}>Resume Subscription</button>
                        <p>Current payments last 4 digits: <b><u>{props.subscriptionInfo.lastFour}</u></b></p>
                        <Link to={"/billingchange"}><p>Change Billing Information?</p></Link>
                        <p><input type='checkbox'
                            value={checked}
                            onChange={handleCheckbox}

                        />
                            <b><u>
                                Confirm Subscription Reinstatement
                            </u></b>
                        </p>
                        {errMessage && <p> {errMessage}</p>}
                    </div>

                </div>



            </div >
        );

    };




    if (props.subscriptionInfo.status === "active" && !props.subscriptionInfo.isPaused) {




        return (
            <div className='iq__account'>


                <div className='iq__account-header'>
                    <h1>Account Dashboard</h1>
                </div>
                <div className='iq__account-div'>
                    <img src={iqlogo} alt="logo"></img>

                    <div className='iq__account-details'>


                        <div className='iq__account-details-container'>

                            <h3>Account Details
                            </h3>

                            <h4>Email:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.email}</p>

                            <h4>Username:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.username}</p>

                        </div>

                        <div className='iq__account-details-subscription'>


                            <h3>Subscription Status

                            </h3>
                            <h4>Status:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.status}</p>
                            <h4>Current Plan:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.plan}</p>

                            <h4>Next Payment:
                                <div className='border_div' />
                            </h4>
                            <p>{props.subscriptionInfo.chargedThroughDate}</p>



                        </div>

                    </div>
                    <div className='iq__account-details-subscription_button'>
                        <button onClick={cancelButton}>Cancel Subscription</button>
                        <p>Current payments last 4 digits: <b><u>{props.subscriptionInfo.lastFour}</u></b></p>
                        <Link to={"/billingchange"}><b className='change_billing'>Change Billing Information?</b></Link>
                        <p><input type='checkbox'
                            value={checked}
                            onChange={handleCheckbox}

                        />
                            <b><u>
                                Confirm Subscription Cancellation
                            </u></b>
                        </p>
                        {errMessage && <p> {errMessage}</p>}

                    </div>

                </div>



            </div >
        );
    };

    if (props.subscriptionInfo.status !== "active") {




        return (
            <div className='iq__account'>


                <div className='iq__account-header'>
                    <h1>Account Dashboard</h1>
                </div>
                <div className='iq__account-div'>
                    <img src={iqlogo} alt="logo"></img>

                    <div className='iq__account-details'>


                        <div className='iq__account-details-container'>

                            <h3>Account Details
                            </h3>

                            <h4>Email:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.email}</p>

                            <h4>Username:
                                <div className='border_div' />
                            </h4>
                            <p>{currentUser.username}</p>

                        </div>

                        <div className='iq__account-details-subscription'>


                            <h3>Subscription Status

                            </h3>
                            <h4>Status:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.status}</p>
                            <h4>Current Plan:
                                <div className='border_div' />

                            </h4>
                            <p>{props.subscriptionInfo.plan}</p>



                        </div>

                    </div>
                    <p>{errMessage}</p>
                    <div className='iq__account-details-subscription_button'>
                        <Link to={"/payment"}><button>Subscribe</button></Link>


                    </div>

                </div>



            </div >
        );
    };

}

export default AccountDashboard;

