import React, {useEffect, useState} from 'react'
import AuthService from "../../services/auth.service";
import "./password_recovery.css"
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
const PasswordRecovery = (props) => {
    const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    useEffect(() => {

        setValidEmail(EMAIL_REGEX.test(email));

    }, [email]);

    const handleRequestSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        AuthService.resetPasswordRequest(
            email,

        ).then((response) => {
            navigation("/");
            props.showToastMessage(response.data.message);
        }).catch(error => {
            props.showToastMessage(error.response.data.message);

        });
        setLoading(false);

    };

    const inputOnchange = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
    };

    return (
        <div className='pwd_recover-container'>
            <form onSubmit={handleRequestSubmit} className='pwd_recover-form' >
                <div className='pwd_recover-header'>
                    <h1>Reset Password</h1>
                    <p>Enter the email associated with your account to begin the recovery process.</p>
                </div>
                <div className='pwd_recover-input'>
                    <label>Email:
                        <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                        <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                    </label>
                    <input onChange={(e) => inputOnchange(e)} placeholder='example@email.com'></input>
                    <button disabled={!validEmail || loading}>Request Reset</button>
                </div>
            </form>

        </div>
    )
}


export default PasswordRecovery;