import React from 'react';
import iqlogo from "../../assets/logo.png";
import "./footer.css";
import { Link } from 'react-router-dom';


const Footer = (props) => {

  const handleRegisterClick = () => {
    props.handleRegClick();

  };

  if (props.userLoggedIn && (props.subscriptionInfo.status === "active" || props.subscriptionInfo.status === "LIFETIME")) {
    return (
      <div className='iq__footer section padding'>
        <div className='iq__footer-heading'>
          <h1 className='gradient__text'>Better bets, no regrets! <br />
            Improve your betting IQ</h1>
        </div>


          <div className='iq__footer-links'>
              <div className='iq__footer-links_logo'>
                  <img src={iqlogo} alt="logo"></img>
              </div>
              <div className='iq__footer-links_div'>
                  <h4>Social Media</h4>

                  <p><Link to="https://www.instagram.com/fightxiq/">Instagram</Link></p>
                  <p><Link to="https://www.facebook.com/FightxIQ">Facebook</Link></p>
                  <p><Link to="https://twitter.com/FightxIQ">Twitter</Link></p>
                  <p><Link to="https://www.tiktok.com/@fightxiq_official?_t=8gSInYWFsWE&_r=1">TikTok</Link></p>

              </div>
              <div className='iq__footer-links_div'>
                  <h4>Company</h4>

                  <p><Link to="/tos">Terms & Conditions</Link></p>
                  <p><Link to="/privacy">Privacy Policy</Link></p>
                  <p><Link to="/ebook">Free Strategies Ebook</Link></p>
              </div>
              <div className='iq__footer-links_div'>
                  <h4>Get In Touch</h4>
                  <p><Link to="https://twitter.com/FightxIQ">Twitter</Link></p>
                  <p><Link to={`mailto:admin@fightxiq.com`}>Email</Link></p>
              </div>


          </div>

          <div className='iq__footer-copyright'>
              <p>Ⓒ 2025 FightxIQ. All rights reserved.</p>
          </div>
      </div>
    )
  }


    if (props.userLoggedIn && props.subscriptionInfo.status === "Inactive") {
        return (
            <div className='iq__footer section padding'>
                <div className='iq__footer-heading'>
                    <h1 className='gradient__text'>Better bets, no regrets! Improve your betting IQ</h1>
        </div>
        <Link to={"/payment"}><div className='iq__footer-button'>
          <p >Subscribe</p>
        </div></Link>

        <div className='iq__footer-links'>
          <div className='iq__footer-links_logo'>
            <img src={iqlogo} alt="logo"></img>
          </div>
          <div className='iq__footer-links_div'>
            <h4>Social Media</h4>

            <p><Link to="https://www.instagram.com/fightxiq/">Instagram</Link></p>
            <p><Link to="https://www.facebook.com/FightxIQ">Facebook</Link></p>
            <p><Link to="https://twitter.com/FightxIQ">Twitter</Link></p>
            <p><Link to="https://www.tiktok.com/@fightxiq_official?_t=8gSInYWFsWE&_r=1">TikTok</Link></p>

          </div>
          <div className='iq__footer-links_div'>
            <h4>Company</h4>

            <p><Link to="/tos">Terms & Conditions</Link></p>
            <p><Link to="/privacy">Privacy Policy</Link></p>

          </div>
            <div className='iq__footer-links_div'>
                <h4>Get In Touch</h4>

                <p><Link to="https://twitter.com/FightxIQ">Twitter</Link></p>
                <p><Link to="http://mail:admin@fightxiq.com">Email</Link></p>

            </div>
        </div>
                <div className='iq__footer-copyright'>
                    <p>Ⓒ 2025 FightxIQ. All rights reserved.</p>
        </div>
      </div>
    )
  }

  return (
    <div className='iq__footer section padding'>
      <div className='iq__footer-heading'>
        <h1 className='gradient__text'>Better bets, no regrets! Improve your betting IQ</h1>
      </div>

      <div onClick={handleRegisterClick} className='iq__footer-button'>
        <p >Sign Up</p>
      </div>

      <div className='iq__footer-links'>
        <div className='iq__footer-links_logo'>
          <img src={iqlogo} alt="logo"></img>
        </div>
        <div className='iq__footer-links_div'>
          <h4>Social Media</h4>

          <p><Link to="https://www.instagram.com/fightxiq/">Instagram</Link></p>
          <p><Link to="https://www.facebook.com/FightxIQ">Facebook</Link></p>
          <p><Link to="https://twitter.com/FightxIQ">Twitter</Link></p>
          <p><Link to="https://www.tiktok.com/@fightxiq_official?_t=8gSInYWFsWE&_r=1">TikTok</Link></p>

        </div>
        <div className='iq__footer-links_div'>
          <h4>Company</h4>

          <p><Link to="/tos">Terms & Conditions</Link></p>
          <p><Link to="/privacy">Privacy Policy</Link></p>

        </div>
          <div className='iq__footer-links_div'>
              <h4>Get In Touch</h4>

              <p><Link to="https://twitter.com/FightxIQ">Twitter</Link></p>
              <p><Link to="mailto:admin@fightxiq.com">Email</Link></p>

          </div>

      </div>
        <div className='iq__footer-copyright'>
            <p>Ⓒ 2025 FightxIQ. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer