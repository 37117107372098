import React from 'react'
import { Feature } from '../../components'
import './whatIq.css'
const WhatIq = () => {
  return (

    <div className='iq__whatiq section__margin' id="whatIq">
      <div className='iq__whatiq-feature'>
        <Feature title="What is FightxIQ?" text="Your ultimate UFC fight analysis companion designed to
        enhance your odds. Uncover upcoming UFC bouts with precise AI-generated predictions and insightful visual comparisons 
        for every fighter. Elevate your confidence and accuracy in betting picks with our unique insights, including 
        prediction percentages and comparative charts. Whether you're a seasoned gambler or just enjoy being right, 
        FightxIQ adds excitement and confidence to your fight predictions!"
        />
      </div>
      <div className='iq__whatiq-heading'>
        <h1 className='gradient__text'>The possibilities are beyond your imagination</h1>

      </div>
      <div className='iq__whatiq-container'>
        <Feature title="AI Predictions" text="All UFC predictions are made by Artificial Intelligence. Our 
                        model is trained from more than 20 years of UFC fight data; The model learns and grows
                        with every fight."/>
        <Feature title="Fighter Analytics" text="Each fighter has their own style. Dive deep into each fighter’s statistics
         with comparative, data-driven visualizations."/>
        <Feature title="Simple Access" text="Sign in, activate your membership, and then navigate to the Predictions page
                        to access upcoming fight predictions"/>
      </div>
    </div>

  )
}

export default WhatIq;