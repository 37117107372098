import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import AuthService from '../../services/auth.service';
import './navbar.css'



const Menu = () => (
  <>

    <p><Link to="/">Home</Link></p>
    <p><a href='#whatIq'>What is FightxIQ?</a></p>
    <p><a href="/ebook">Free Betting Ebook</a></p>


  </>
);
const UserMenu = () => (
    <>

        <p><Link to="/">Home</Link></p>
        <p><a href='/#whatIq'>What is FightxIQ?</a></p>
        <p><Link to="/account">Account</Link></p>
        <p><Link to="/payment">Subscribe</Link></p>
        <p><a href="/ebook">Free Betting Ebook</a></p>

    </>
);

const PrivateMenu = () => (
    <>

        <p><Link to="/">Home</Link></p>
    <p><a href='/#whatIq'>What is FightxIQ?</a></p>
    <p><Link to="/account">Account</Link></p>
    <p><Link to="/predictions">Predictions Dashboard</Link></p>


  </>
);


const Navbar = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);



  const handleSignOut = () => {

    AuthService.logout();
    window.location.replace('/');

  };

  const handleRegisterClick = () => {
    props.handleRegClick();
  };
  const handleSigninClick = () => {
    props.handleLoginClick();
  };



  // Subscribers NavBar
  if (props.userLoggedIn && (props.subscriptionInfo.status === "active" || props.subscriptionInfo.status === "LIFETIME")) {
    return (
      <div className='iq__navbar'>
        <div className='iq__navbar-links'>
          <div className='iq__navbar-links_logo'>
            <img src={logo} alt="logo" />
          </div>
          <div className='iq__navbar-links_container'>
            <PrivateMenu />




          </div>
        </div>
        <div className='iq__navbar-sign'>


          <div className='iq__navbar-menu'>
            <div className='.iq__navbar-menu_logged-in'>
              {toggleMenu
                ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
              }
              {toggleMenu && (
                <div className='iq__navbar-menu_container scale-up-center'>
                  <div className='iq__navbar-menu_containter-links'>
                    <PrivateMenu />
                    <button onClick={handleSignOut}>Sign Out</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div >
    )
  };

  // Users NavBar
  if (props.userLoggedIn) {
    return (
      <div className='iq__navbar'>
        <div className='iq__navbar-links'>
          <div className='iq__navbar-links_logo'>
            <img src={logo} alt="logo" />
          </div>
          <div className='iq__navbar-links_container'>
            <UserMenu />




          </div>
        </div>
        <div className='iq__navbar-sign'>


          <div className='iq__navbar-menu'>
            <div className='.iq__navbar-menu_logged-in'>
              {toggleMenu
                ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
                : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
              }
              {toggleMenu && (
                <div className='iq__navbar-menu_container scale-up-center'>
                  <div className='iq__navbar-menu_containter-links'>
                    <UserMenu />
                    <button onClick={handleSignOut}>Sign Out</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div >
    )
  };



  return (
    <div className='iq__navbar'>
      <div className='iq__navbar-links'>
        <div className='iq__navbar-links_logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='iq__navbar-links_container'>
          <Menu />




        </div>
      </div>
      <div className='iq__navbar-sign'>
        <p onClick={handleSigninClick}>Sign In</p>
        <button type='button' onClick={handleRegisterClick}>Sign Up</button>
      </div>

      <div className='iq__navbar-menu_public'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='iq__navbar-menu_container scale-up-center'>
            <div className='iq__navbar-menu_containter-links'>
              <Menu />
              <div className='iq__navbar-menu_containter-links-sign'>
                <p onClick={handleSigninClick}>Sign In</p>
                <button type='button' onClick={handleRegisterClick}>Sign Up</button>
              </div>
            </div>
          </div>
        )}

      </div>

    </div>
  )
}

export default Navbar