import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis, Tooltip, Cell, LabelList, ResponsiveContainer } from 'recharts';
import { GiSlashedShield, GiBullseye, GiPunch } from "react-icons/gi";
import "../../chart_tooltip_styling.css";
import "../chart_styling.css";

const StrikingAccuracyDefense = (props) => {
    const data = [

        {
            stat: "Striking Accuracy",
            rData: ~~(props.data[props.index].r_career_significant_strikes_accuracy * 100),
            bData: ~~(props.data[props.index].b_career_significant_strikes_accuracy * 100),
            bName: props.data[props.index].b_name,
            rName: props.data[props.index].r_name,
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",
        },
        {
            stat: "Striking Defense",
            rData: ~~(props.data[props.index].r_career_significant_strikes_defense * 100),
            bData: ~~(props.data[props.index].b_career_significant_strikes_defense * 100),
            bName: props.data[props.index].b_name,
            rName: props.data[props.index].r_name,
            rColorIndex: "#FF5858",
            bColorIndex: "#4c9cf8",

        },

    ];


    return (
        <ResponsiveContainer width={"99%"} height={"100%"}>
            <BarChart

                data={data}
                barCategoryGap={10}
                barGap={5}
                margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="stat" xAxisId={"red"} tickLine={false} tick={CustomizedLabelXAxis} interval={0}  >

                </XAxis>

                <YAxis unit="%" domain={[0, dataMax => (~~dataMax + 10)]} />
                <Tooltip content={<CustomToolTip />} cursor={{ fill: "white", opacity: "15%" }} />
                <Legend content={<CustomLegend />} xAxisId={"red"} align='center' verticalAlign='top' />

                <Bar dataKey={"rData"} xAxisId='red' icon={<GiPunch />} name={data[0].rName} color={"#FF5858"} >

                    <LabelList content={<CustomizedLabelBar dataKey={"StrikeAcc"} />} position="top" />


                    {


                        data.map((entry, index) => (

                            <Cell key={`cell-${index}`} dataKey="StrikeAcc" fill={entry.rColorIndex} fillOpacity={"55%"} stroke={entry.rColorIndex} strokeLinejoin='round' strokeWidth={3.5}  >

                            </Cell>

                        ))
                    }  </Bar>
                <Bar dataKey={"bData"} xAxisId='red' icon={<GiPunch />} name={data[0].bName} color={"#4c9cf8"}  >
                    <LabelList content={<CustomizedLabelBar dataKey={"StrikeDef"} />} />

                    {
                        data.map((entry, index) => (

                            < Cell key={`cell-${index}`} dataKey="StrikeDef" fill={entry.bColorIndex} fillOpacity={"55%"} stroke={entry.bColorIndex} strokeLinejoin='round' strokeWidth={3.5} />
                        ))

                    }  </Bar>
            </BarChart >
        </ResponsiveContainer>
    )
};
const CustomLegend = (props) => {
    const payload = props.payload;
    return (
        <span className='AD_legend'>
            {
                payload.map((entry, index) => (

                    <div key={`item-${index}`}>
                        <span style={{ color: entry.payload.color, paddingRight: "4px" }}>{entry.payload.icon}</span>
                        <span style={{ color: entry.payload.color }} >{entry.value}</span>
                    </div>

                ))

            }
        </span>

    )
};
const CustomToolTip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
        return (
            <div className='custom-tooltip' style={{ boxShadow: payload[0].payload.stat === "Striking Accuracy" ? "0px 0px 9px white" : "0px 0px 9px #f39c12" }}>


                <div className='multi-stat_header'>

                    <h3 >


                        <div className='custom-tooltip_icon'>
                            {payload[0].payload.stat === "Striking Accuracy" ? <GiBullseye size={40} color={"white"} /> : <GiSlashedShield size={40} color={"#f39c12"} />}

                        </div>
                        {payload[0].payload.stat}
                    </h3>

                </div>

                <div className='custom-toolip_multi-div'>
                    <div>
                        <b style={{ color: payload[0].payload.rColorIndex }}>{payload[0].name}</b>
                        <p > {payload[0].value}%</p>

                    </div>
                    <div>
                        <b style={{ color: payload[1].payload.bColorIndex }}>{payload[1].name}</b>
                        <p > {payload[1].value}%</p>
                    </div>
                </div>





            </div>
        );
    }
    return null;
};
const CustomizedLabelBar = (props) => {
    const { x, y, value, index } = props;
    const payload = props;
    return (<g>


        {index === 0 ? <GiBullseye className='bar_icon' x={x + 2} y={y + 2} style={{ fill: index === 0 ? "white" : "#ffd700", stroke: "black", strokeWidth: 12 }} /> : <GiSlashedShield className='bar_icon' x={x + 2} y={y + 2} style={{ fill: index === 1 ? "#f39c12" : "white", stroke: "black", strokeWidth: 6 }} />}

        <text className='strikes_LA_bar-value' x={x} y={y - 5} fontSize={30} style={{ fill: payload.fill, stroke: "white" }} >
            {value}%</text>









    </g>
    );
}

const CustomizedLabelXAxis = (props) => {
    const { index, x, y, payload } = props;

    return (

        <g className='custom_XAxis-Label_LA' transform={`translate(${x},${y})`} >



            <text x={-20} y={0} dy={16} textAnchor="right" fill={"grey"}  >

                {payload.value}

            </text>
            <g transform={`translate(${-39},${1})`} color={index === 0 ? "white" : "#f39c12"} > {payload.value === "Striking Defense" ? <GiSlashedShield /> : <GiBullseye />}</g>

        </g >


    );

};



export { StrikingAccuracyDefense };