import { RiCloseLine } from 'react-icons/ri';
import React, { useRef, useState, useEffect, } from "react";
import AuthService from "../../../services/auth.service";
import { withRouter } from "../../../common/with-router";
import "./login.css";
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
    const nav = useNavigate();
    const userRef = useRef();
    const errRef = useRef();


    const [username, setUsername] = useState("");
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState("");
    const [pwdFocus, setPwdFocus] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, []);

    const handleLoginClick = () => {
        props.handleLoginClick();

    };

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        AuthService.login(
            username,
            pwd
        ).then((e) => {

            props.setIsShowLogin(!props.isShowLogin);

            props.setSuccess(true);
            props.showToastMessage(`Welcome back ${e.username}`);
            nav("/account");
        },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||

                    error.message ||
                    error.toString();
                props.showToastMessage(resMessage, "error");

            }

        );
        setLoading(false);


    }


    return (

        <section className={`${props.isShowLogin ? "active" : ""} show`}>

            {/*<p ref={errRef} className={message ? "errmsg" : "offscreen"} aria-live="assertive">{message}</p>*/}
            <form className='login_form' onSubmit={handleLoginSubmit}>
                <div className='exitLogin'>
                    <span><RiCloseLine color='#fff' size={27} onClick={handleLoginClick} /></span>

                </div>
                <div className="formControl">

                    <h1>Sign In</h1>
                    <div>
                        <label htmlFor="username">
                            Username:
                        </label>
                        <input
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            required
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
                    </div>
                    <div>
                        <label htmlFor="password">
                            Password:

                        </label>

                        <input
                            type="password"
                            id="password"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            required
                            aria-describedby="pwdnote"
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                        />
                        <a href="/recovery" className="login_reset">Forgot Password?</a>
                    </div>


                    {/*<p className='signIn-pwd_recovery' onClick={props.handleLoginClick}><Link to={"/passwordRecoveryReset"}>Forgot Password?</Link></p>*/}
                    <button disabled={(username?.length === 0) || (pwd.length === 0) || loading}>Sign In</button>
                </div>
            </form>

        </section>

    )
}


export default withRouter(Login);