import React, {useEffect, useState} from 'react'
import "./password_recovery_reset.css"
import {useNavigate, useSearchParams} from "react-router-dom";
import AuthService from "../../services/auth.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faInfoCircle, faTimes} from "@fortawesome/free-solid-svg-icons";
import LoadingScreen from "../../components/loading/LoadingScreen";

const PasswordRecoveryReset = (props) => {
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [validMatch, setValidMatch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const navigation = useNavigate();
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState();


    let newID = "";

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(password));
        setValidMatch(password === confirmPassword);
    }, [password, confirmPassword]);



    useEffect(() => {
        setPageLoading(true);
        newID = searchParams.get('id');
        if (newID === null) {
            navigation("/recovery");
            props.showToastMessage("Please request a valid password reset link below.")
        }
        else {
            AuthService.passwordResetAccess(newID)
                .then((response) => {
                    props.showToastMessage(response.data.message)
                    setEmail(response.data.email);
            }).catch((error) => {
                navigation("/recovery");
                props.showToastMessage(error.response.data.errorMessage)

            })
        }
        setPageLoading(false);
    }, []);


    const passwordChangeSubmit = (e) => {
        setLoading(true);

        e.preventDefault();
        AuthService.passwordReset(email, password)
            .then((response) => {
                navigation("/")
                props.showToastMessage(response.data.message);
            })
            .catch((error) => {
                props.showToastMessage(error.data.message);

        })
        setLoading(false);
    };

    if (pageLoading || loading) {
        return (<LoadingScreen />)
    }

    return (
        <div className='pwd_new-container'>
            <form onSubmit={passwordChangeSubmit} className='pwd_new-form'>
                <div className='pwd_new-header'>
                    <h1>Password Reset</h1>
                    <p>Enter and confirm your new password.</p>
                </div>
                <div className='pwd_new-input'>
                    <label>New Password:
                        <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"}/>
                        <FontAwesomeIcon icon={faTimes} className={validPwd || !password ? "hide" : "invalid"}/>
                    </label>
                    <input placeholder='NewPassword123'
                           type='password'
                           onChange={(e) => setPassword(e.target.value)}
                           value={password}
                           required
                           aria-invalid={validPwd ? "false" : "true"}
                           aria-describedby="pwdnote"
                           onFocus={() => setPwdFocus(true)}
                           onBlur={() => setPwdFocus(false)}
                    ></input>
                    <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                        8 to 24 characters.<br/>
                        Must include uppercase and lowercase letters, a number and a special character.<br/>
                        Allowed special characters:
                        <span role="list" aria-label="exclamation mark">!</span>
                        <span role="list" aria-label="at symbol">@</span>
                        <span role="list" aria-label="hashtag">#</span>
                        <span role="list" aria-label="dollar sign">$</span>
                        <span role="list" aria-label="percent">%</span>

                    </p>
                    <label>Confirm Password:
                        <FontAwesomeIcon icon={faCheck} className={validMatch ? "valid" : "hide"}/>
                        <FontAwesomeIcon icon={faTimes} className={validMatch || !password ? "hide" : "invalid"}/>
                    </label>

                    <input placeholder='NewPassword123'
                           type='password'
                           onChange={(e) => setConfirmPassword(e.target.value)}
                           value={confirmPassword}
                           aria-invalid={validPwd ? "false" : "true"}
                           onFocus={() => setPwdFocus(true)}
                           onBlur={() => setPwdFocus(false)}
                    ></input>
                    <button disabled={!validPwd || !confirmPassword || loading}>Reset Password</button>
                </div>
            </form>

        </div>
    )
}


export default PasswordRecoveryReset;